<template>
  <CourseScopeIndex
      :create-text="'New Course'"
      :can-create="
      !!$store.getters['entities/user-permissions/find']('create course-scopes')
    "
      :can-delete="
      !!$store.getters['entities/user-permissions/find']('delete course-scopes')
    "
      :tabs="[{ title: `Course List` }]"
  ></CourseScopeIndex>
</template>
<script>

import CourseScopeIndex from "@/components/courses/CourseScopeIndex";

export default {
  name: "Course.index",
  components: {CourseScopeIndex},
};
</script>