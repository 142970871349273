<template>
  <section class="hero campus-header is-light is-large mb-5">
    <div v-if="showHead" class="hero-head">
      <nav :class="{'is-hidden-touch':!showMobile}" class="navbar pl-4 ml-3 has-text-grey-darker">
        <div class="is-flex is-fullwidth is-justify-content-space-between is-align-items-center">
          <div :class="{'skeleton-container':!loaded}" class="is-flex">
            <p v-if="loaded" class="is-size-3 has-text-grey-darker">
              {{
                showCampus ? CampusData.name : ''
              }}{{ showCampus && headerText ? ' - ' : '' }}{{
                headerText ? `${headerText}` : ''
              }} </p>
            <div v-else style="width: 100%">
              <b-skeleton :animated="true" width="100%"></b-skeleton>
              <b-skeleton :animated="true" width="100%"></b-skeleton>

            </div>
            <a :class="{'is-hidden-touch':!showMobile}" aria-expanded="false" aria-label="menu" class="navbar-burger"
               role="button"
               @click="showMenu =!showMenu">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>

          </div>
          <div id="navbarMenuHeroB" :class="{'is-active':showMenu}" class="is-flex">
            <div class="  ">

              <div class="">
                <b-field group-multiline grouped>
                  <b-field v-if="canCreate">
                    <b-button :icon-left="$tc('icons.create')" class="has-drop-shadow" type="is-light"
                              @click="startCreating">
                      {{ createText }}
                    </b-button>
                  </b-field>

                  <b-field v-if="CampusesData.length>1 && canSelect && loaded" type="is-primary">
                    <b-select v-model="CampusSelection" class="is-borderless has-text-primary"
                              placeholder="Select a campus">
                      <option v-if="canAll" value="All">All</option>
                      <option
                          v-for="campus in CampusesData"
                          :key="campus.id"
                          :value="campus.id">
                        {{ campus.name }}
                      </option>
                    </b-select>

                  </b-field>
                  <b-field v-if="canSelectYear" type="is-primary">
                    <b-select v-model="$store.state.dates.year" class="is-borderless has-text-primary"
                              placeholder="Year">
                      <option
                          v-for="year of $store.getters['dates/years']"
                          :key="year + 'year'"
                          :value="year"
                      >{{ year }}
                      </option>
                    </b-select>

                  </b-field>
                  <b-field
                      v-if="canSelectClassGroups && $store.state.campus.loadedClassGroups && (ClassGroups.length>1 ||canAllClassGroups)"
                      type="is-primary">
                    <b-select v-model="ClassGroupSelection" :placeholder="(canAllClassGroups&&(ClassGroupSelection===null))?'All Classes':'Select a class'"
                              class="is-borderless has-text-primary">
                      <option v-if="canAllClassGroups" :value="null">All Classes</option>
                      <option
                          v-for="classGroup in ClassGroups"
                          :key="classGroup.id"
                          :value="classGroup.id">
                        {{ classGroup.name }}
                      </option>
                    </b-select>

                  </b-field>

                  <b-field
                      v-if="canSelectWards && (Wards.length>1)"
                      type="is-primary">
                    <b-select v-model="WardSelection" class="is-borderless has-text-primary"
                              placeholder="Select a ward">
                      <option
                          v-for="Ward in Wards"
                          :key="Ward.id"
                          :value="Ward.id">
                        {{ Ward.first_name }}
                      </option>
                    </b-select>

                  </b-field>
                  <b-field v-if="closeButton" class="">
                    <b-button icon-right="cancel" type="is-danger" @click="$emit('close')">
                      Close
                    </b-button>
                  </b-field>
                </b-field>
              </div>

            </div>
          </div>
        </div>
      </nav>
      <nav :class="{'is-hidden-desktop':!showMobile,'mb-3':canCreate||(canSelect && loaded)}||canSelectClassGroups">
        <p v-if="loaded" class=" navbar-item is-size-3 is-size-4-mobile has-text-grey-darker" style="max-width: 100%">
          {{
            showCampus ? CampusData.name : ''
          }}{{ showCampus && headerText ? ' - ' : '' }}{{
            headerText ? `${headerText}` : ''
          }} </p>

        <div>
          <b-field class="unset-group-multiline-margin-bottom" group-multiline grouped position="is-right">
            <b-field v-if="canCreate">
              <b-button :icon-left="$tc('icons.create')" class="has-drop-shadow" type="is-light"
                        @click="startCreating">
                {{ createText }}
              </b-button>
            </b-field>
            <b-field v-if="CampusesData.length>1 && canSelect && loaded" type="is-primary">
              <b-select v-model="CampusSelection" class="is-borderless has-text-primary"
                        placeholder="Select a campus">
                <option v-if="canAll" value="All">All</option>
                <option
                    v-for="campus in CampusesData"
                    :key="campus.id"
                    :value="campus.id">
                  {{ campus.name }}
                </option>
              </b-select>

            </b-field>
            <b-field
                v-if="canSelectWards && (Wards.length>1)"
                type="is-primary">
              <b-select v-model="WardSelection" class="is-borderless has-text-primary"
                        placeholder="Select a ward">
                <option
                    v-for="Ward in Wards"
                    :key="Ward.id"
                    :value="Ward.id">
                  {{ Ward.first_name }}
                </option>
              </b-select>

            </b-field>
            <b-field v-if="canSelectYear" type="is-primary">
              <b-select v-model="$store.state.dates.year" class="is-borderless has-text-primary" placeholder="Year">
                <option
                    v-for="year of $store.getters['dates/years']"
                    :key="year + 'year'"
                    :value="year"
                >{{ year }}
                </option>
              </b-select>

            </b-field>
            <b-field
                v-if="canSelectClassGroups && $store.state.campus.loadedClassGroups && (ClassGroups.length>1 ||canAllClassGroups)"
                type="is-primary">
              <b-select v-model="ClassGroupSelection" :placeholder="(canAllClassGroups&&(ClassGroupSelection===null))?'All Classes':'Select a class'"
                        class="is-borderless has-text-primary">
                <option v-if="canAllClassGroups" :value="null">All Classes</option>
                <option
                    v-for="classGroup in ClassGroups"
                    :key="classGroup.id"
                    :value="classGroup.id">
                  {{ classGroup.name }}
                </option>
              </b-select>
            </b-field>
            <b-field
                v-if="canSelectHomeClasses && $store.state.campus.loadedHomeClasses && (HomeClasses.length>1 ||canAllHomeClasses)"
                type="is-primary">
              <b-select v-model="HomeClassSelection" class="is-borderless has-text-primary"
                        placeholder="Select a homeclass">
                <option v-if="canAllHomeClasses" :value="null">All</option>
                <option
                    v-for="HomeClass in HomeClasses"
                    :key="HomeClass.id"
                    :value="HomeClass.id">
                  {{ HomeClass.name }}
                </option>
              </b-select>

            </b-field>

          </b-field>
        </div>
      </nav>
    </div>

    <!--    <div class="hero-body">-->
    <!--      <div class="container has-text-centered">-->
    <!--        <p class="title">-->
    <!--          Title-->
    <!--        </p>-->
    <!--        <p class="subtitle">-->
    <!--          Subtitle-->
    <!--        </p>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="hero-foot">
      <nav v-if="header_tabs.length>1 && !hideTabs" class="tabs is-toggle is-fullwidth">
        <PerfectScrollbarWrapper class="pb-3">
          <ul>
            <li v-for="(tab,index) in header_tabs" :key="index+ 'tab'" :class="{'is-active':activeTab===index}"
                @click="changeTab(index)">
              <a>{{ tab.title }}</a>
            </li>

          </ul>
        </PerfectScrollbarWrapper>
      </nav>
    </div>
  </section>
</template>

<script>
import Campus from "@/models/Campus";
import ClassGroup from "@/models/ClassGroup";
import HomeClass from "@/models/HomeClass";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
import User from "@/models/User";

export default {
  components: {PerfectScrollbarWrapper},
  name: "CampusHeader",
  data() {
    return {
      loaded: false,
      showMenu: false
    }
  },
  watch: {
    manageClassGroups(newValue) {
      if (newValue === true) {
        ClassGroup.FetchAll({
          page: 1,
          limit: 999
        }, {...(this.classGroupSubjectId ? {subject_id: this.classGroupSubjectId} : {})}).then(response => {
          if (response.response.data.data.length > 0) {
            let classGroupId = parseInt(this.$store.state.campus.selected_class_group_id !== null ? this.$store.state.campus.selected_class_group_id : response.response.data.data[0].id)
            if (!response.response.data.data.some(group => group.id === classGroupId)) {
              classGroupId = response.response.data.data[0].id
            }
            this.$store.dispatch('campus/selectClassGroup', classGroupId)
            ClassGroup.FetchById(classGroupId).then(() => {
              this.$store.state.campus.loadedClassGroups = true
            })
          } else {
            this.$store.dispatch('campus/selectClassGroup', null)
            this.$store.state.campus.loadedClassGroups = true
          }
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        this.$store.state.campus.loadedClassGroups = false
      }
    }
  },
  mounted() {
    if (this.manageClassGroups) {
      ClassGroup.FetchAll({
        page: 1,
        limit: 999
      }, {...(this.classGroupSubjectId ? {subject_id: this.classGroupSubjectId} : {})}).then(response => {
        if (response.response.data.data.length > 0) {
          let classGroupId = parseInt(this.$store.state.campus.selected_class_group_id !== null ? this.$store.state.campus.selected_class_group_id : response.response.data.data[0].id)
          if (!response.response.data.data.some(group => group.id === classGroupId)) {
            classGroupId = response.response.data.data[0].id
          }
          if (this.$route.query.class_group_id) {
            classGroupId = parseInt(this.$route.query.class_group_id)
          }
          this.setQuery({class_group_id: classGroupId})
          this.$store.dispatch('campus/selectClassGroup', classGroupId)
          ClassGroup.FetchById(classGroupId).then(() => {
            this.$store.state.campus.loadedClassGroups = true
          })
        } else {
          this.$store.dispatch('campus/selectClassGroup', null)
          this.$store.state.campus.loadedClassGroups = true
        }
      }).catch(err => {
        this.handleError(err)
      })
    }
    if (this.canSelectWards) {
      User.FetchById(parseInt(this.$store.state.user.id),['wards']).then(response => {
        if (response.response.data.data.attributes.wards.length > 0) {
          let wardId = parseInt(this.$store.state.campus.selected_ward_id !== null ? this.$store.state.campus.selected_ward_id : response.response.data.data.attributes.wards[0].id)
          if (!response.response.data.data.attributes.wards.some(ward => ward.id === wardId)) {
            wardId = response.response.data.data.attributes.wards[0].id
          }
          if (this.$route.query.ward_id) {
            wardId = parseInt(this.$route.query.ward_id)
          }
          this.setQuery({ward_id: wardId})
          this.$store.dispatch('campus/selectWard', wardId)

        } else {
          this.$store.dispatch('campus/selectWard', null)
        }
      }).catch(err=>{

        this.handleError(err)

      });

    }
    if (this.manageCampuses) {
      Campus.FetchAll({page: 1, limit: 100}).then(response => {
        if (response.response.data.data.length > 0) {
          let campusId = parseInt(this.$store.state.campus.selected_campus_id !== null ? this.$store.state.campus.selected_campus_id : response.response.data.data[0].id)
          if (this.$route.query.campus_id) {
            campusId = parseInt(this.$route.query.campus_id)
          }

          this.$store.dispatch('campus/selectCampus', campusId)
          Campus.FetchById(campusId, ['phases']).then(() => {
            this.loaded = true
            this.$emit('loaded')

          })
        } else {
          this.noCampuses = true
          this.$emit('loaded')
          this.loaded = true
        }
      })
    } else {
      if (!this.noCampusOverride) {
        this.loaded = true
      }
    }
  },
  computed: {
    ClassGroups() {
      return ClassGroup.query().where(classGroup => {
        return this.classGroupSubjectId ? this.classGroupSubjectId === classGroup.subject_id : true
      }).get()
    },
    Wards() {
      return User.query().whereId(parseInt(this.$store.state.user.id)).with("wards").first()?.wards ?? Array;
    },
    HomeClasses() {
      return HomeClass.query().get()
    },
    CampusesData() {
      if (this.manageCampuses) {
        return Campus.query().where((campus) => {
          if (this.showNotOpenForEnrolment === false) {
            return campus.is_open_for_enrolment === 1
          } else {
            return true

          }
        }).orderBy('ordinality').get()
      }
      return this.campuses
    }, CampusData() {
      if (this.manageCampuses) {
        return Campus.query().whereId(this.$store.state.campus.selected_campus_id).first();

      }
      return this.campus
    },
    CampusSelection: {
      get() {
        return this.CampusData.id
      }, set(id) {

        this.$emit('campus-selected', id)
        if (id !== 'All') {
          if (this.manageCampuses) {
            this.setQuery({campus_id: id})
            this.$store.dispatch('campus/selectCampus', id)
          }
        } else {
          this.removeQuery(['campus_id'])
          this.$store.dispatch('campus/setAll', true)
        }
      }
    },

    ClassGroupSelection: {
      get() {
        return this.$store.state.campus.selected_class_group_id
      }, set(id) {

        this.$emit('class-group-selected', id)
        if (this.canSelectClassGroups) {
          this.setQuery({class_group_id: id})
          this.$store.dispatch('campus/selectClassGroup', id)
        }

      }
    }, WardSelection: {
      get() {
        return this.$store.state.campus.selected_ward_id
      }, set(id) {

        this.$emit('ward-selected', id)
        if (this.canSelectWards) {
          this.setQuery({ward_id: id})
          this.$store.dispatch('campus/selectWard', id)
          console.log('s')
        }

      }
    },

    HomeClassSelection: {
      get() {
        return this.$store.state.campus.selected_home_class_id
      }, set(id) {

        this.$emit('home-class-selected', id)
        if (this.canSelectHomeClasses) {
          this.$store.dispatch('campus/selectHomeClass', id)
        }

      }
    }

  },
  props: {
    hideTabs: {
      type: Boolean,
      default() {
        return false
      }
    },
    canAllClassGroups: {
      type: Boolean,
      default() {
        return false
      }
    }, canAllHomeClasses: {
      type: Boolean,
      default() {
        return false
      }
    },
    canAll: {
      type: Boolean, default() {
        return false
      }
    }, showMobile: {
      type: Boolean, default() {
        return false
      }
    },

    showHead: {
      type: Boolean,
      default() {
        return true
      }
    }, showCampus: {
      type: Boolean,
      default() {
        return false
      }
    },
    noCampusOverride: {
      type: Boolean, default() {
        return false
      }
    },
    canSelect: {
      type: Boolean, default() {
        return true
      }
    },
    canSelectClassGroups: {
      type: Boolean, default() {
        return false
      }
    },    canSelectWards: {
      type: Boolean, default() {
        return false
      }
    },

    canSelectHomeClasses: {
      type: Boolean, default() {
        return false
      }
    }, canSelectYear: {
      type: Boolean, default() {
        return false
      }
    },
    closeButton: {
      type: Boolean, default() {
        return false
      }
    },
    headerText: {
      type: String, required: false
    }, createText: {
      type: String, default() {
        return 'New Campus'
      }
    }, manageCampuses: {
      type: Boolean, default() {
        return false
      }
    }, manageClassGroups: {
      type: Boolean, default() {
        return false
      }
    }, manageHomeClasses: {
      type: Boolean, default() {
        return false
      }
    }, classGroupSubjectId: {
      type: Number, default() {
        return null
      }
    },
    canCreate: {
      type: Boolean, default() {
        return false
      }
    },
    campuses: {
      type: Array, default() {
        return [{name: 'No Campus', id: 1}]
      },
    },
    campus: {
      type: Object, default() {
        return {name: 'No Campus', id: 1}
      }
    },
    header_tabs: {
      type: Array,
      default() {
        return [{title: 'Manage Academics'}, {title: 'Campus Information'}]
      }
    },
    activeTab: {
      type: Number,
      default() {
        return 0
      }
    },
    showNotOpenForEnrolment: {type: Boolean, default: true, required: false}
  }
  ,
  methods: {
    startCreating() {
      this.$emit('start-creating')
    },
    changeTab(index) {
      this.$emit('change-tab', index)
    }
  }
}
</script>

<style scoped>

</style>
