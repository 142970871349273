
<template>
  <form @submit.prevent="submit">
    <ValidationObserver ref="observer">
      <div :class="{'modal-card':inModal}" style="width: auto">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Course</p>
        </header>
        <section :class="{'modal-card-body':inModal}">
            <b-field expanded label="Curriculum">
              <ValidationProvider
                rules="required:min-items:1"
                v-slot="{ errors }"
              >
                <b-select expanded v-model="courseObject.learning_stream_id" placeholder="Curriculum">
                  <option v-for="stream in learningStreams" :key="stream.id" :value="stream.id">{{ stream.name }}</option>
                </b-select>
                <span class="help is-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
            <b-field expanded label="Phase">
              <ValidationProvider
                rules="required:min-items:1"
                v-slot="{ errors }"
              >
                <b-select expanded @input="courseObject.stage_ids =null" v-model="courseObject.phase_id"
                          placeholder="Phase">
                  <option v-for="phase in phases" :key="phase.id" :value="phase.id">{{ phase.name }}</option>
                </b-select>
                <span class="help is-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
            <b-field expanded label="Grade">
              <ValidationProvider
                  rules="required|min-items:1"
                  v-slot="{ errors }"
              >
                <b-dropdown
                    append-to-body
                    expanded
                    v-model="courseObject.stage_ids"
                    multiple
                    aria-role="list"
                >

                  <b-button class="select" expanded slot="trigger"
                            :disabled="courseObject.phase_id===null"
                  >
                    Grade
                  </b-button>

                  <b-dropdown-item
                      v-for="stage in stages"
                      :type="{'is-hidden': courseObject.phase_id === null }"
                      :key="stage.id"
                      :value="stage.id"
                      aria-role="listitem"
                      required
                  >
                    <span>{{ stage.name }}</span>
                  </b-dropdown-item>
                </b-dropdown>
                <span
                    :class="{'is-hidden': courseObject.phase_id === null }"
                    class="help is-danger"
                >
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </b-field>
            <b-field expanded label="Examination Year">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <b-input expanded min="2019" type="number" :max="maxYear" v-model="courseObject.examination_year"
                        placeholder="Examination Year">
                </b-input>
                <span class="help is-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
              <b-field expanded label="Syllabus">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-input expanded placeholder="Syllabus" v-model="courseObject.syllabus">
                  </b-input>
                  <span class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-field expanded label="Code">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-input expanded placeholder="Code" v-model="courseObject.code">
                  </b-input>
                  <span class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-tooltip
                  class="is-fullwidth"
                  label="Recommended number of teaching hours for this unit"
                  size="is-small"
                  type="is-primary"
                  position="is-top"
                  multilined
              >
                <b-field expanded label="Hours">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-input expanded type="number" v-model="courseObject.hours" placeholder="30">
                    </b-input>
                    <span class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                </b-field>
              </b-tooltip>
              <b-tooltip
                  class="is-fullwidth"
                  label="Terms required to complete syllabus"
                  size="is-small"
                  type="is-primary"
                  position="is-top"
                  multilined
              >
                <b-field expanded label="Terms">
                  <ValidationProvider
                  rules="required:min-items:1"
                  v-slot="{ errors }"
                  >
                    <b-select expanded placeholder="Terms" v-model="courseObject.terms">
                      <option v-for="i in 6" :key="i" :value="i">
                        {{ i }}
                      </option>
                    </b-select>
                    <span class="help is-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>

              </b-tooltip>
              <b-tooltip
                  class="is-fullwidth"

                  label="Weeks required to complete syllabus"
                  size="is-small"
                  type="is-primary"
                  position="is-top"
                  multilined
              >
                <b-field expanded label="Weeks">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-input expanded type="number" v-model="courseObject.weeks" min="1" max="99" placeholder="30">
                    </b-input>
                    <span class="help is-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>

              </b-tooltip>
              <b-tooltip
                  label="Lessons per week
  to complete syllabus"
                  class="is-fullwidth"

                  size="is-small"
                  type="is-primary"
                  position="is-top"
                  multilined
              >
                <b-field expanded label="Lessons">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-input expanded type="number" v-model="courseObject.lessons" placeholder="30">
                    </b-input>
                    <span class="help is-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>

              </b-tooltip>
              <b-tooltip
                  class="is-fullwidth"

                  label="Time per lesson"
                  size="is-small"
                  type="is-primary"
                  position="is-top"
                  multilined
              >
                <b-field expanded label="Time">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-timepicker
                        :time-formatter="formatTime"
                        append-to-body
                        expanded
                        v-model="courseMinutes"
                        placeholder="00:00"
                        :hour-format="'24'"
                        position="is-top-right"
                    >
                    </b-timepicker>
                    <span class="help is-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>

              </b-tooltip>
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
          <b-field v-if="!inModal" class="has-text-centered">
            <b-button native-type="submit" type="is-primary">Save</b-button>
          </b-field>
        </section>
        <footer v-if="inModal" :class="{'modal-card-foot':inModal}">
          <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
          </button>
        </footer>
      </div>
    </ValidationObserver>
  </form>
</template>

<script>
import CourseScope from "@/models/CourseScope";
import LearningStream from "@/models/LearningStream";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import Campus from "@/models/Campus";
import {format, hoursToMinutes} from "date-fns";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("min-items", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "Please select a field"
});

export default {
  name: "CourseScopeForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      maxYear:new Date().getFullYear() + 10,
      isLoading: false,
      courseObject: {
        campus_id: this.campus_id,
        learning_stream_id: null,
        phase_id: null,
        stage_ids: null,
        examination_year: new Date().getFullYear(),
        syllabus: null,
        hours: null,
        terms: null,
        weeks: null,
        lessons: null,
        time: null
      },
    }
  },
  computed: {
    learningStreams() {
      return LearningStream.query().get()
    },
    phases() {
      return Phase.query().get()
    },
    stages() {
      return Stage.query().where('phase_id', this.courseObject.phase_id).get()
    },
    courseMinutes: {
      set(newVal) {
        this.courseObject.time =  hoursToMinutes(newVal.getHours()) + newVal.getMinutes()
      },
      get() {
        if (this.courseObject.time == null) {
          return null;
        }
        const hours = Math.floor(this.courseObject.time/60)
        const minutes = this.courseObject.time % 60
        return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), hours, minutes)
      },
    },

  },
  methods: {
    formatTime(time) {
      return format(time, 'HH:mm')
    },
    submit() {
      this.$refs.observer.validate().then((response) => {
        if (response) {
          if (this.edit) {
            this.isLoading = true
            CourseScope.Update(this.courseObject, true).then(() => {
              this.isLoading = false
              this.$buefy.snackbar.open(`Course updated!`)
              this.$emit('close')
            }).catch(err => {
              this.isLoading = false
              this.handleError(err)
            })
          } else {
            this.isLoading = true
            CourseScope.Store(this.courseObject).then((result) => {
              this.isLoading = false
              this.$buefy.snackbar.open(`Course created!`)
              this.$emit('course-created', result.response.data.data.id)
              this.$emit('close')
            }).catch(err => {
              this.isLoading = false
              this.handleError(err)
            })
          }
        }
      })

    },

  },
  watch: {
    submitBitFlip(newVal) {
      if (newVal === 1) {
        this.submit()
      }
    }
  },
  mounted() {
    this.isLoading = true
    Phase.deleteAll()
    Stage.deleteAll()
    Promise.all([LearningStream.FetchAll(), Campus.FetchAll({
      page: 1,
      limit: 15
    }, {is_master: 1}, ['phases', 'phases.stages'])],).then(() => {

      this.isLoading = false
    })
    if (this.edit) {
      this.isLoading = false
      this.courseObject = this.course
    } else {
      this.courseMinutes =new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0)
    }
  },
  props: {
    submitBitFlip: {
      type: Number, default() {
        return 0
      }
    },
    campus_id: {
      type: Number, default() {
        return 0
      }
    },
    course: {
      type: Object,
      default() {
        return {
          name: ''
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

