<template>
  <div>
    <CampusHeader
        :can-create="canCreate"
        :activeTab="0"
        @start-creating="startCreatingCourse"
        :manage-campuses="false"
        :can-all="false"
        :can-select="false"
        :header-text="headerText"
        :hide-tabs="true"
        :create-text="createText"
        :header_tabs="[{ title: `${tabEntity} List` }]"
    ></CampusHeader>
    <section class="section pt-4 box">
      <div class="">
        <div class="columns">
          <div class="column">
            <b-field grouped>
              <b-field expanded>
                <b-select :disabled="$store.state.campus.all_campuses" expanded placeholder="Phase"
                          v-model="phase_filter">
                  <option
                      v-for="phase in phases"
                      :key="phase.id"
                      :value="phase.id"
                  >{{ phase.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field expanded>
                <b-select :disabled="!phaseSearchId" expanded placeholder="Stage"
                          v-model="stageSearchId">
                  <option
                      v-for="stage in stages"
                      :key="stage.id"
                      :value="stage.id"
                  >{{ stage.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field>
                <b-button @click="clear()">
                  Clear
                </b-button>
              </b-field>
            </b-field>
          </div>
        </div>
        <hr>
        <b-table
            class="margin-top is-clickable"
            :data="courses"
            :loading="loadingData"
            :striped="true"
            :hoverable="true"
            :bordered="false"
            @click="view"
        >
          <template #empty>
            <div class="has-text-centered">No {{ headerText }}</div>
          </template>
          <!--          :backend-sorting="true"-->
          <!--          :default-sort="['first_name', 'asc']"-->
          <!--          @sort="onSort"-->
          <b-table-column
              v-slot="props"
              label="Code"
              field="code"
              sortable
              width="40"
              numeric
          >{{ props.row.code }}
          </b-table-column>

          <!--          <b-table-column-->
          <!--              v-slot="props"-->
          <!--              sortable-->
          <!--              field="learning_stream.name"-->
          <!--              label="Learning Stream"-->
          <!--          >{{ props.row.learning_stream.name }}-->
          <!--          </b-table-column>-->
          <b-table-column
              v-slot="props"
              sortable
              field="syllabus"
              label="Syllabus"
          >{{ props.row.syllabus }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              label="Actions"
              custom-key="actions"
              centered

          >
            <div v-on:click.stop>
              <b-dropdown position="is-bottom-left"
                          append-to-body aria-role="list">
                <template #trigger>
                  <b-icon :icon="$tc('icons.more')"/>
                </template>

                <b-dropdown-item v-if="canDelete" @click="startDelete(props.row)" aria-role="listitem">
                  Delete
                </b-dropdown-item>

              </b-dropdown>
            </div>
          </b-table-column>


        </b-table>
        <b-pagination
            class="mt-4"
            :total="meta.total"
            :current="page"
            :range-before="2"
            :range-after="2"
            :per-page="limit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            v-on:change="setPage"
        ></b-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import Campus from "@/models/Campus";
import CourseScope from "@/models/CourseScope";
import CourseScopeForm from "@/components/courses/CourseScopeForm";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import {mapState} from "vuex";

export default {
  name: "Course.index",
  components: {CampusHeader},
  data() {
    return {

      search: null,
      loadingFilter: false,
      courseDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null
    };
  },
  props: {

    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Courses";
      },
    },
    createText: {
      type: String,
      default() {
        return "Course";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Course";
      },
    },

  },
  methods: {
    clear() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },
    view(course){
      this.$router.push(`courses/edit/${course.id}`)
    },

    startDelete(course) {
      this.$buefy.dialog.confirm({
        title: `Deleting course`,
        confirmText: `Delete course`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this course?`,
        onConfirm: () =>
            CourseScope.Delete(course.id)
                .then(() => {
                  this.$buefy.snackbar.open(`course deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreatingCourse() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          campus_id: this.$store.state.campus.selected_campus_id,
          canCreate: this.canCreate,
        },
        component: CourseScopeForm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "course-created": (id) => {
            this.viewCourse({id: id});
          },
        },
      });
    },

    viewCourse(user) {
      this.$cookies.set(
          `course_filters`,
         {
            page: this.page,
            phaseSearchId: this.phaseSearchId,
            stageSearchId: this.stageSearchId
          },
          "1d"
      );
      if (this.use_modal) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
          },
          component: CourseScopeForm,
          fullScreen: true,
          trapFocus: true,
        });
      } else {
        this.$router.push(`/courses/edit/${user.id}`);
      }
    },
    getFilteredCourses(text) {
      this.search = text;
    },
    setPage(pageNumber, initial = false) {
      this.loadingData = true;
      this.courseDataHold = this.courses;
      CourseScope.deleteAll();
      this.page = pageNumber;
      CourseScope.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters, ['learning_stream', 'stages']
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.courseDataHold = null;
            if (!initial) {
              this.$cookies.set(
                  `course_filters`,
                  {
                    page: this.page,
                    phaseSearchId: this.phaseSearchId,
                    stageSearchId: this.stageSearchId
                  },
                  "1d"
              );
            }
          }
      );
    },
  },
  watch: {

    all_campuses(value) {
      if (value === true) {
        this.phaseSearchId = null
        this.stageSearchId = null
      }
    },
    selected_campus_id() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    phase_filter: {
      set(val) {
        this.phaseSearchId = val
        this.stageSearchId = null
      }, get() {
        return this.phaseSearchId
      }
    },
    ...mapState('campus', ["selected_campus_id", "all_campuses"]),
    campuses() {
      return Campus.query().get();
    },
    phases() {
      return Phase.query().get()
    },
    stages() {
      return Stage.query().where('phase_id', this.phaseSearchId).get()
    },
    filters() {
      return {
        ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {}), ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {}),
        ...(this.phaseSearchId
            ? {
              phase_id: this.phaseSearchId,
            }
            : {}), ...(this.stageSearchId
            ? {
              stage_id: this.stageSearchId,
            }
            : {}),

      };
    },
    courses() {
      if (this.courseDataHold === null) {
        return CourseScope.query().with('learning_stream').get();
      } else {
        return this.courseDataHold;
      }
    },

  },
  mounted() {
    this.$store.dispatch("loader/show");
    if (this.$cookies.isKey(`course_filters`)) {
      let filters = this.$cookies.get(`course_filters`);
      this.page = filters.page;
      this.phaseSearchId = filters.phaseSearchId;
      this.stageSearchId = filters.stageSearchId
      this.order_by = filters.order_by
      this.order_direction = filters.order_direction
      this.$cookies.remove(`course_filters`);
    }
    Phase.deleteAll()
    Stage.deleteAll()
    Promise.all([Campus.FetchAll({page: 1, limit: 15}, {is_master: 1}, ['phases', 'phases.stages'])]).then(() => {
      this.$store.dispatch("loader/hide");
      this.setPage(this.page, true);
    }).catch(
        err => {
          this.$store.dispatch("loader/hide");

          if (err.response.status === 422) {
            this.handleError(err)
          } else {
            this.handleError(err)
          }
        }
    );
  },
};
</script>
