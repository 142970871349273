var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer"},[_c('div',{class:{'modal-card':_vm.inModal},staticStyle:{"width":"auto"}},[(_vm.inModal)?_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.edit === false ? 'Create a new' : 'Edit')+" Course")])]):_vm._e(),_c('section',{class:{'modal-card-body':_vm.inModal}},[_c('b-field',{attrs:{"expanded":"","label":"Curriculum"}},[_c('ValidationProvider',{attrs:{"rules":"required:min-items:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"expanded":"","placeholder":"Curriculum"},model:{value:(_vm.courseObject.learning_stream_id),callback:function ($$v) {_vm.$set(_vm.courseObject, "learning_stream_id", $$v)},expression:"courseObject.learning_stream_id"}},_vm._l((_vm.learningStreams),function(stream){return _c('option',{key:stream.id,domProps:{"value":stream.id}},[_vm._v(_vm._s(stream.name))])}),0),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-field',{attrs:{"expanded":"","label":"Phase"}},[_c('ValidationProvider',{attrs:{"rules":"required:min-items:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"expanded":"","placeholder":"Phase"},on:{"input":function($event){_vm.courseObject.stage_ids =null}},model:{value:(_vm.courseObject.phase_id),callback:function ($$v) {_vm.$set(_vm.courseObject, "phase_id", $$v)},expression:"courseObject.phase_id"}},_vm._l((_vm.phases),function(phase){return _c('option',{key:phase.id,domProps:{"value":phase.id}},[_vm._v(_vm._s(phase.name))])}),0),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-field',{attrs:{"expanded":"","label":"Grade"}},[_c('ValidationProvider',{attrs:{"rules":"required|min-items:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-dropdown',{attrs:{"append-to-body":"","expanded":"","multiple":"","aria-role":"list"},model:{value:(_vm.courseObject.stage_ids),callback:function ($$v) {_vm.$set(_vm.courseObject, "stage_ids", $$v)},expression:"courseObject.stage_ids"}},[_c('b-button',{staticClass:"select",attrs:{"slot":"trigger","expanded":"","disabled":_vm.courseObject.phase_id===null},slot:"trigger"},[_vm._v(" Grade ")]),_vm._l((_vm.stages),function(stage){return _c('b-dropdown-item',{key:stage.id,attrs:{"type":{'is-hidden': _vm.courseObject.phase_id === null },"value":stage.id,"aria-role":"listitem","required":""}},[_c('span',[_vm._v(_vm._s(stage.name))])])})],2),_c('span',{staticClass:"help is-danger",class:{'is-hidden': _vm.courseObject.phase_id === null }},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-field',{attrs:{"expanded":"","label":"Examination Year"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"expanded":"","min":"2019","type":"number","max":_vm.maxYear,"placeholder":"Examination Year"},model:{value:(_vm.courseObject.examination_year),callback:function ($$v) {_vm.$set(_vm.courseObject, "examination_year", $$v)},expression:"courseObject.examination_year"}}),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-field',{attrs:{"expanded":"","label":"Syllabus"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"expanded":"","placeholder":"Syllabus"},model:{value:(_vm.courseObject.syllabus),callback:function ($$v) {_vm.$set(_vm.courseObject, "syllabus", $$v)},expression:"courseObject.syllabus"}}),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-field',{attrs:{"expanded":"","label":"Code"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"expanded":"","placeholder":"Code"},model:{value:(_vm.courseObject.code),callback:function ($$v) {_vm.$set(_vm.courseObject, "code", $$v)},expression:"courseObject.code"}}),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-tooltip',{staticClass:"is-fullwidth",attrs:{"label":"Recommended number of teaching hours for this unit","size":"is-small","type":"is-primary","position":"is-top","multilined":""}},[_c('b-field',{attrs:{"expanded":"","label":"Hours"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"expanded":"","type":"number","placeholder":"30"},model:{value:(_vm.courseObject.hours),callback:function ($$v) {_vm.$set(_vm.courseObject, "hours", $$v)},expression:"courseObject.hours"}}),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-tooltip',{staticClass:"is-fullwidth",attrs:{"label":"Terms required to complete syllabus","size":"is-small","type":"is-primary","position":"is-top","multilined":""}},[_c('b-field',{attrs:{"expanded":"","label":"Terms"}},[_c('ValidationProvider',{attrs:{"rules":"required:min-items:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"expanded":"","placeholder":"Terms"},model:{value:(_vm.courseObject.terms),callback:function ($$v) {_vm.$set(_vm.courseObject, "terms", $$v)},expression:"courseObject.terms"}},_vm._l((6),function(i){return _c('option',{key:i,domProps:{"value":i}},[_vm._v(" "+_vm._s(i)+" ")])}),0),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-tooltip',{staticClass:"is-fullwidth",attrs:{"label":"Weeks required to complete syllabus","size":"is-small","type":"is-primary","position":"is-top","multilined":""}},[_c('b-field',{attrs:{"expanded":"","label":"Weeks"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"expanded":"","type":"number","min":"1","max":"99","placeholder":"30"},model:{value:(_vm.courseObject.weeks),callback:function ($$v) {_vm.$set(_vm.courseObject, "weeks", $$v)},expression:"courseObject.weeks"}}),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-tooltip',{staticClass:"is-fullwidth",attrs:{"label":"Lessons per week\nto complete syllabus","size":"is-small","type":"is-primary","position":"is-top","multilined":""}},[_c('b-field',{attrs:{"expanded":"","label":"Lessons"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"expanded":"","type":"number","placeholder":"30"},model:{value:(_vm.courseObject.lessons),callback:function ($$v) {_vm.$set(_vm.courseObject, "lessons", $$v)},expression:"courseObject.lessons"}}),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-tooltip',{staticClass:"is-fullwidth",attrs:{"label":"Time per lesson","size":"is-small","type":"is-primary","position":"is-top","multilined":""}},[_c('b-field',{attrs:{"expanded":"","label":"Time"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-timepicker',{attrs:{"time-formatter":_vm.formatTime,"append-to-body":"","expanded":"","placeholder":"00:00","hour-format":'24',"position":"is-top-right"},model:{value:(_vm.courseMinutes),callback:function ($$v) {_vm.courseMinutes=$$v},expression:"courseMinutes"}}),_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),(!_vm.inModal)?_c('b-field',{staticClass:"has-text-centered"},[_c('b-button',{attrs:{"native-type":"submit","type":"is-primary"}},[_vm._v("Save")])],1):_vm._e()],1),(_vm.inModal)?_c('footer',{class:{'modal-card-foot':_vm.inModal}},[_c('button',{staticClass:"button",attrs:{"type":"is-primary","icon-right":_vm.$tc(("icons." + (_vm.edit===false?'create':'edit')))}},[_vm._v("Save ")])]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }